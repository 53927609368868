import {
  Challenge,
  Play,
  PlayRequest,
  League,
  Prize,
  RedeemPrizeResponse,
  User,
  RedemptionsResponse,
  TransactionsResponse,
  FeatureFlags,
} from '../interfaces';

import { apiUrl } from '../App';

declare global {
  interface Window {
    __pxHeaders?: string;
    __AccountID?: string;
    __AuthToken?: string;
    __FeatureFlags?: string;
  }
  interface Window {
    webkit: Webkit;
    ReactNativeWebView: {
      postMessage(msg: string): void;
    };
  }

  interface Webkit {
    messageHandlers: {
      /**
       * Added due to our call to addScriptMessageHandler.
       * @see: https://github.com/react-native-community/react-native-webview/blob/25552977852427cf5fdc7b233fd1bbc7c77c18b0/ios/RNCWebView.m#L1244
       */
      ReactNativeWebView: {
        postMessage(message: string): void;
      };
      /**
       * Added due to our call to addScriptMessageHandler.
       * @see: https://github.com/react-native-community/react-native-webview/blob/25552977852427cf5fdc7b233fd1bbc7c77c18b0/ios/RNCWebView.m#L214
       */
      ReactNativeHistoryShim: {
        postMessage(message: string): void;
      };
    };
  }
}

/** NEW ENDPOINTS FOR engagement-service backend */

async function handleUnauthorizedRequest(windowValues: WindowValues) {
  // When the queries run since they're lazily called, we will check if the accountId is an empty string still. If it is, then its failing auth.
  // We also will check if the accountId is not a number. If it is not, then its also failing auth.
  // This will happen if the auth header is malformed or if the `injectJavaScript` from RN is not called and the accountId starts as `null` which it does in the initial load of the gamecenter frontend app.
  if (windowValues.accountId === '' || !parseInt(windowValues.accountId)) {
    const evPayload = {
      type: 'ON_FETCH_RESPONSE',
      body: `Unauthorized`,
      status: 401,
      url: '',
    };
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(evPayload));
    }
    throw new Error(`Unauthorized`);
  }
  return true;
}

function checkForGeolocationBlock(response: Response) {
  // Realistically we only need this on /GameCenter since thats the landing page, but im adding it to every "else" condition in case we end up deep linking to different pages in the future.
  if (response.status === 403) {
    // const _responseText = response.text;
    location.href = '/CountryNotAllowed';
  }
}

const sendErrorToApp = async (result: Response, pxHeaders: string) => {
  if (pxHeaders) {
    // in case of status 403, we will ask the app container (VS App) to display PX blocker if needed
    const clonedResult: Response = result.clone();
    if (!clonedResult.ok) {
      const evPayload = {
        type: 'ON_FETCH_RESPONSE',
        body: await clonedResult.text(),
        status: clonedResult.status,
        url: clonedResult.url,
      };
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(evPayload));
      }
    }
  }
  return result;
};

export interface WindowValues {
  accountId: string;
  authToken: string;
  pxHeaders: string;
  featureFlags: Array<FeatureFlags>;
}
const setHeaders = (windowValues: WindowValues) => {
  return {
    'X-Account': windowValues.accountId,
    'X-Auth-Token': windowValues.authToken,
    ...JSON.parse(windowValues.pxHeaders || '{}'),
  };
};

export const postLitePlay = (windowValues: WindowValues) =>
  async function ({ play }: { play: PlayRequest }): Promise<{ points: number }> {
    const response = await fetch(apiUrl + '/lite', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        ...setHeaders(windowValues),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(play),
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error posting lite play', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error posting lite play ${response.status}`);
    }
  };

export const putLitePlay = (windowValues: WindowValues) =>
  async function (code: string): Promise<{ points: number }> {
    const response = await fetch(apiUrl + '/lite', {
      method: 'PUT',
      cache: 'no-cache',
      headers: {
        ...setHeaders(windowValues),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error putting puttingPromoCode', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error putting puttingPromoCode ${response.status}`);
    }
  };

export const getLiteGames = (windowValues: WindowValues) =>
  async function ({ queryKey }: { queryKey: (string | number | undefined)[] }): Promise<Challenge> {
    const [_] = queryKey;
    const response = await fetch(apiUrl + '/lite', {
      headers: setHeaders(windowValues),
      cache: 'no-cache',
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      checkForGeolocationBlock(response);
      console.error('Error fetching lite', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error fetching promo ${response.status}`);
    }
  };

export const getUserRedemptions =
  (windowValues: WindowValues) => async (): Promise<RedemptionsResponse[]> => {
    await handleUnauthorizedRequest(windowValues);

    const response = await fetch(apiUrl + '/redemptions?', {
      cache: 'no-cache',
      headers: setHeaders(windowValues),
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      checkForGeolocationBlock(response);
      console.error('Error fetching user redemptions', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      console.error('Error fetching user redemptions', response.status);
      throw new Error(`Error fetching user redemptions ${response.status}`);
    }
  };

export const getUserTransactions =
  (windowValues: WindowValues) => async (): Promise<TransactionsResponse[]> => {
    await handleUnauthorizedRequest(windowValues);

    const response = await fetch(apiUrl + '/transactions?', {
      cache: 'no-cache',
      headers: setHeaders(windowValues),
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      checkForGeolocationBlock(response);
      console.error('Error fetching user transactions', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      console.error('Error fetching user transactions', response.status);
      throw new Error(`Error fetching user transactions ${response.status}`);
    }
  };

export const postPlay = (windowValues: WindowValues) =>
  async function ({ play }: { play: PlayRequest }): Promise<Play | false> {
    await handleUnauthorizedRequest(windowValues);

    const response = await fetch(apiUrl + '/plays', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        ...setHeaders(windowValues),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(play),
    });
    if (response.ok) {
      const json = (await response.json()) as Play;
      return json;
    } else {
      console.error('Error posting play', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error posting play ${response.status}`);
    }
  };

export const postRedemption = (windowValues: WindowValues) =>
  async function ({ prize }: { prize: Prize }): Promise<RedeemPrizeResponse | false> {
    await handleUnauthorizedRequest(windowValues);

    const response = await fetch(apiUrl + '/redemptions', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        ...setHeaders(windowValues),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prizeId: prize.id,
      }),
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      checkForGeolocationBlock(response);
      console.error('Error redeeming prize', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error redeeming prize ${response.status}`);
    }
  };

export const getPrizesV2 = (windowValues: WindowValues) => async () => {
  await handleUnauthorizedRequest(windowValues);

  const response = await fetch(apiUrl + '/prizes', {
    headers: setHeaders(windowValues),
    cache: 'no-cache',
  });
  if (response.ok) {
    const json = await response.json();
    return json as Prize[];
  } else {
    checkForGeolocationBlock(response);
    await sendErrorToApp(response, windowValues.pxHeaders);
    console.error('Error fetching prizes', response.status);
    throw new Error(`Error fetching prizes ${response.status}`);
  }
};

export const getChallengesV2 = (windowValues: WindowValues) =>
  async function ({ queryKey }: { queryKey: (string | undefined)[] }): Promise<Challenge[]> {
    await handleUnauthorizedRequest(windowValues);

    const [_, limit = '20', userChallenges = 'false', challengeType = '', excludedTypes = ''] =
      queryKey;
    const response = await fetch(
      apiUrl +
        '/challenges?' +
        new URLSearchParams({ limit, userChallenges, type: challengeType, excludedTypes }),
      { cache: 'no-cache', headers: setHeaders(windowValues) },
    );
    if (response.ok) {
      const json = await response.json();
      return json.sort(
        (a: Challenge, b: Challenge) =>
          new Date(b.endDate).valueOf() - new Date(a.endDate).valueOf(),
      );
    } else {
      checkForGeolocationBlock(response);
      console.error('Error fetching challenges', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error fetching challenges ${response.status}`);
    }
  };

export const getChallengeV2 = (windowValues: WindowValues) =>
  async function ({ queryKey }: { queryKey: (string | number | undefined)[] }): Promise<Challenge> {
    await handleUnauthorizedRequest(windowValues);

    const [_, challengeId] = queryKey;
    const response = await fetch(apiUrl + '/challenges/' + challengeId, {
      headers: setHeaders(windowValues),
      cache: 'no-cache',
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      checkForGeolocationBlock(response);
      console.error('Error fetching challenges', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error fetching challenge ${response.status}`);
    }
  };

export const getUserV2 = (windowValues: WindowValues) =>
  async function (): Promise<User> {
    await handleUnauthorizedRequest(windowValues);

    const response = await fetch(apiUrl + '/users?' + new URLSearchParams({ includePoints: '1' }), {
      cache: 'no-cache',
      headers: setHeaders(windowValues),
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      checkForGeolocationBlock(response);
      console.error('Error fetching user', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error('Error fetching user ${response.status}');
    }
  };

export const getLeagueV2 = (windowValues: WindowValues) =>
  async function ({ queryKey }: { queryKey: (string | undefined)[] }): Promise<League> {
    await handleUnauthorizedRequest(windowValues);

    const [_, leagueId] = queryKey;
    const response = await fetch(apiUrl + '/leagues/' + leagueId, {
      method: 'GET',
      headers: setHeaders(windowValues),
      cache: 'no-cache',
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error fetching league', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw await response.text();
    }
  };

export const getLeaguesV2 = (windowValues: WindowValues) =>
  async function (): Promise<League[]> {
    await handleUnauthorizedRequest(windowValues);

    const response = await fetch(apiUrl + '/leagues?' + new URLSearchParams({ limit: '100' }), {
      headers: setHeaders(windowValues),
      cache: 'no-cache',
    });
    if (response.ok) {
      const json = await response.json();
      return json as League[];
    } else {
      console.error('Error fetching leagues', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      return [] as League[];
    }
  };

export const createLeagueV2 = (windowValues: WindowValues) =>
  async function (
    league: Partial<League> & { admin: Partial<League['members'][number]> },
  ): Promise<League> {
    await handleUnauthorizedRequest(windowValues);

    const body = JSON.stringify({ ...league, admin: league.admin });
    const response = await fetch(apiUrl + '/leagues', {
      method: 'POST',
      body,
      cache: 'no-cache',
      headers: {
        'Content-type': 'application/json',
        ...setHeaders(windowValues),
      },
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error creating league', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error creating league ${response.status}`);
    }
  };

export const joinLeagueV2 = (windowValues: WindowValues) =>
  async function (league: Partial<League>): Promise<League> {
    await handleUnauthorizedRequest(windowValues);

    const body = JSON.stringify(league);
    const response = await fetch(apiUrl + '/leagues', {
      method: 'PUT',
      body,
      cache: 'no-cache',
      headers: {
        'Content-type': 'application/json',
        ...setHeaders(windowValues),
      },
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error joining league', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw await response.text();
    }
  };

export const updateLeagueV2 = (windowValues: WindowValues) =>
  async function ({
    queryKey,
    league,
  }: {
    queryKey: (string | undefined)[];
    league: Partial<League>;
  }): Promise<League> {
    await handleUnauthorizedRequest(windowValues);

    const [_, leagueId] = queryKey;
    const body = JSON.stringify(league);
    const response = await fetch(apiUrl + '/leagues/' + leagueId, {
      method: 'PUT',
      body,
      cache: 'no-cache',
      headers: {
        'Content-type': 'application/json',
        ...setHeaders(windowValues),
      },
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error updating league', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw await response.text();
    }
  };

export const closeLeagueV2 = (windowValues: WindowValues) =>
  async function ({ queryKey }: { queryKey: (string | undefined)[] }): Promise<League> {
    await handleUnauthorizedRequest(windowValues);

    const [_, leagueId] = queryKey;
    const response = await fetch(apiUrl + '/leagues/' + leagueId, {
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
        'Content-type': 'application/json',
        ...setHeaders(windowValues),
      },
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      console.error('Error closing league', response.status);
      await sendErrorToApp(response, windowValues.pxHeaders);
      throw new Error(`Error closing league ${response.status}`);
    }
  };
