import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { FLAGS } from '../constants/Flags';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import DText from './DText';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';

export function PlayNextGameOrBackToGameCenterButton({
  onPress,
  activeGameToBePlayed,
}: {
  onPress: () => void;
  activeGameToBePlayed: boolean;
}) {
  const { enabled: gameCenterFavoritingEnabled } = useFeatureFlags(FLAGS.GAME_CENTER_FAVORITING);

  return (
    <BiggerTouchableOpacity
      onPress={onPress}
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: gameCenterFavoritingEnabled ? colorPalette.cobalt['400'] : 'transparent',
        backgroundColor: gameCenterFavoritingEnabled ? 'transparent' : colorPalette.cobalt['400'],
        paddingTop: 6,
        paddingBottom: 6,
        height: 36,
      }}
    >
      <DText
        color={gameCenterFavoritingEnabled ? colorPalette.cobalt['400'] : colorPalette.white['100']}
        fontSize={Fonts.font16}
      >
        {activeGameToBePlayed ? 'Play Next Game' : 'Back to Game Center'}
      </DText>
    </BiggerTouchableOpacity>
  );
}
