import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { PerformerSummary } from '../interfaces';
import { colorPalette } from '../themes/variables';
import DText from './DText';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { FLAGS } from '../constants/Flags';

export default function FavoritePerformerButton({ performer }: { performer: PerformerSummary }) {
  const { enabled: gameCenterFavoritingEnabled } = useFeatureFlags(FLAGS.GAME_CENTER_FAVORITING);

  const buttonText = performer?.isFavorited ? `Favorited!` : `Favorite ${performer?.performerName}`;

  if (!gameCenterFavoritingEnabled) {
    return null;
  }

  return (
    <BiggerTouchableOpacity
      onPress={() => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'POST_FAVORITE_PERFORMER', performerId: performer?.performerId }),
        );
      }}
      style={{
        width: '100%',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        height: 32,
        backgroundColor: colorPalette.cobalt['400'],
      }}
    >
      <DText color="white">{buttonText}</DText>
    </BiggerTouchableOpacity>
  );
}
