export const TeamImages = {
  // NFL
  'Arizona Cardinals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148720/Product/app/features/game-center/pick-ems/nfl/arizona-cardinals-thumbnail_r0bc5h.png',
  'Atlanta Falcons':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146871/Product/app/features/game-center/pick-ems/nfl/atlanta-falcons-thumbnail_kpsv9n.png',
  'Baltimore Ravens':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148703/Product/app/features/game-center/pick-ems/nfl/baltimore-ravens-thumbnail_ep4i8i.png',
  'Buffalo Bills':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148705/Product/app/features/game-center/pick-ems/nfl/buffulo-bills-thumbnail_zrjyui.png',
  'Carolina Panthers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146858/Product/app/features/game-center/pick-ems/nfl/carolina-panthers-thumbnail_lzkrix.png',
  'Chicago Bears':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148704/Product/app/features/game-center/pick-ems/nfl/chicago-bears-thumbnail_zhtsxd.png',
  'Cincinnati Bengals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148700/Product/app/features/game-center/pick-ems/nfl/cincinnati-bengals-thumbnail_wh9idu.png',
  'Cleveland Browns':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146870/Product/app/features/game-center/pick-ems/nfl/cleveland-browns-thumbnail_tff9iq.png',
  'Dallas Cowboys':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146868/Product/app/features/game-center/pick-ems/nfl/dallas-cowboys-thumbnail_zhjsi1.png',
  'Denver Broncos':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148716/Product/app/features/game-center/pick-ems/nfl/denver-broncos-thumbnail_ra6ccf.png',
  'Detroit Lions':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148699/Product/app/features/game-center/pick-ems/nfl/detroit-lions-thumbnail_qcftll.png',
  'Green Bay Packers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148694/Product/app/features/game-center/pick-ems/nfl/green-bay-packers-thumbnail_ssoe43.png',
  'Houston Texans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146863/Product/app/features/game-center/pick-ems/nfl/houston-texans-thumbnail_vktjsx.png',
  'Indianapolis Colts':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148691/Product/app/features/game-center/pick-ems/nfl/indianapolis-colts-thumbnail_rca5og.png',
  'Jacksonville Jaguars':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148717/Product/app/features/game-center/pick-ems/nfl/jacksonville-jaguars-thumbnail_d6yxrr.png',
  'Kansas City Chiefs':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148721/Product/app/features/game-center/pick-ems/nfl/kansas-city-chiefs-thumbnail_zxnbrw.png',
  'Las Vegas Raiders':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148691/Product/app/features/game-center/pick-ems/nfl/las-vegas-raiders-thumbnail_vs9fhk.png',
  'Los Angeles Chargers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148724/Product/app/features/game-center/pick-ems/nfl/los-angeles-chargers-thumbnail_qmckrn.png',
  'Los Angeles Rams':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146873/Product/app/features/game-center/pick-ems/nfl/los-angeles-rams-thumbnail_nmfdcc.png',
  'Miami Dolphins':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146874/Product/app/features/game-center/pick-ems/nfl/miami-dolphins-thumbnail_pgbpwd.png',
  'Minnesota Vikings':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146872/Product/app/features/game-center/pick-ems/nfl/minnesota-vikings-thumbnail_wbrn8i.png',
  'New England Patriots':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146859/Product/app/features/game-center/pick-ems/nfl/new-england-patriots-thumbnail_ql8kvf.png',
  'New Orleans Saints':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723146876/Product/app/features/game-center/pick-ems/nfl/new-orleans-saints-thumbnail_zkjgkr.png',
  'New York Giants':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148688/Product/app/features/game-center/pick-ems/nfl/new-york-giants-thumbnail_zaxict.png',
  'New York Jets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148689/Product/app/features/game-center/pick-ems/nfl/new-york-jets-thumbnail_vnnyvt.png',
  'Philadelphia Eagles':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148713/Product/app/features/game-center/pick-ems/nfl/philadelphia-eagles-thumbnail_ym7mqj.png',
  'Pittsburgh Steelers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148687/Product/app/features/game-center/pick-ems/nfl/pittsburgh-thumbnail_whhzgg.png',
  'San Francisco 49ers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723147172/Product/app/features/game-center/pick-ems/nfl/san-francisco-49ers-thumbnail_tdmakt.png',
  'Seattle Seahawks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148704/Product/app/features/game-center/pick-ems/nfl/seattle-seahawks-thumbnail_tnoxms.png',
  'Tampa Bay Buccaneers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723148722/Product/app/features/game-center/pick-ems/nfl/tampa-bay-buccaneers-thumbnail_yhrdn2.png',
  'Tennessee Titans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723147065/Product/app/features/game-center/pick-ems/nfl/tennessee-titans-thumbnail_uukkkm.png',
  'Washington Commanders':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1723147137/Product/app/features/game-center/pick-ems/nfl/washington-commanders-thumbnail_untztv.png',

  // NBA
  'Atlanta Hawks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379843/Product/app/features/game-center/pick-ems/nba/atlanta-hawks-2_naf5zg.png',
  'Boston Celtics':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379843/Product/app/features/game-center/pick-ems/nba/boston-celtics-2_yjztxy.png',
  'Brooklyn Nets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379843/Product/app/features/game-center/pick-ems/nba/brooklyn-nets-2_ommjoi.png',
  'Charlotte Hornets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379844/Product/app/features/game-center/pick-ems/nba/charolette-hornets-3_oafdgx.png',
  'Chicago Bulls':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379844/Product/app/features/game-center/pick-ems/nba/chicago-bulls-2_twkbjq.png',
  'Cleveland Cavaliers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379846/Product/app/features/game-center/pick-ems/nba/cleveland-cavaliers-2_ctklso.png',
  'Dallas Mavericks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379843/Product/app/features/game-center/pick-ems/nba/dallas-mavericks-2_jd4itw.png',
  'Denver Nuggets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379844/Product/app/features/game-center/pick-ems/nba/denver-nuggets-2_eyysfy.png',
  'Detroit Pistons':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379844/Product/app/features/game-center/pick-ems/nba/detroit-pistons-2_kwsqsw.png',
  'Golden State Warriors':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379848/Product/app/features/game-center/pick-ems/nba/golden-state-warriors-2_blmfk3.png',
  'Houston Rockets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379849/Product/app/features/game-center/pick-ems/nba/houston-rockets-2_ae4kom.png',
  'Indiana Pacers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379844/Product/app/features/game-center/pick-ems/nba/indiana-pacers-2_pog2e8.png',
  'LA Clippers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379846/Product/app/features/game-center/pick-ems/nba/los-angeles-clippers-4_g54w9r.png',
  'Los Angeles Lakers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379847/Product/app/features/game-center/pick-ems/nba/los-angeles-lakers-2_romi8r.png',
  'Memphis Grizzlies':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379844/Product/app/features/game-center/pick-ems/nba/memphis-grizzlies-2_l51aey.png',
  'Miami Heat':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379847/Product/app/features/game-center/pick-ems/nba/miami-heat-3_q03fzc.png',
  'Milwaukee Bucks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379904/Product/app/features/game-center/pick-ems/nba/milwaukee-bucks-3_gmwts6.png',
  'Minnesota Timberwolves':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379850/Product/app/features/game-center/pick-ems/nba/minnesota-timberwolves-3_wqqsia.png',
  'New Orleans Pelicans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739380735/Product/app/features/game-center/pick-ems/nba/new-orleans-pelicans-2_lfslln.png',
  'New York Knicks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379847/Product/app/features/game-center/pick-ems/nba/new-york-knicks-2_ujqyac.png',
  'Oklahoma City Thunder':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379849/Product/app/features/game-center/pick-ems/nba/oklahoma-city-thunder-2_zxlrq2.png',
  'Orlando Magic':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379850/Product/app/features/game-center/pick-ems/nba/orlando-magic-2_xmjkme.png',
  'Philadelphia 76ers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379850/Product/app/features/game-center/pick-ems/nba/philadelphia-76ers-2_unyumw.png',
  'Phoenix Suns':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379851/Product/app/features/game-center/pick-ems/nba/phoenix-suns-2_jxt2kq.png',
  'Portland Trail Blazers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379846/Product/app/features/game-center/pick-ems/nba/portland-trail-blazers-2_ez4vcz.png',
  'Sacramento Kings':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379845/Product/app/features/game-center/pick-ems/nba/sacramento-kings-2_ilsq6h.png',
  'San Antonio Spurs':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379851/Product/app/features/game-center/pick-ems/nba/san-antonio-spurs-2_xca2qo.png',
  'Toronto Raptors':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379845/Product/app/features/game-center/pick-ems/nba/toronto-raptors-2_jduhr5.png',
  'Utah Jazz':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379845/Product/app/features/game-center/pick-ems/nba/utah-jazz-2_rkl6lh.png',
  'Washington Wizards':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379852/Product/app/features/game-center/pick-ems/nba/washington-wizzards-3_j7menb.png',

  // MLB
  'Arizona Diamondbacks':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379578/Product/app/features/game-center/pick-ems/mlb/arizona-diamondbacks-3_e6ej1l.png',
  Athletics:
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379578/Product/app/features/game-center/pick-ems/mlb/athletics_tsiyh5.png',
  'Atlanta Braves':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379578/Product/app/features/game-center/pick-ems/mlb/atlanta-braves-3_wbff6r.png',
  'Baltimore Orioles':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/baltimore-orioles-3_rrsy9j.png',
  'Boston Red Sox':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/boston-red-sox-3_gyiida.png',
  'Chicago Cubs':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/chicago-cubs-3_kfvx5a.png',
  'Chicago White Sox':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/chicago-white-sox-3_fjj7bm.png',
  'Cincinnati Reds':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/cincinnati-reds-3_usls3k.png',
  'Cleveland Guardians':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/cleveland-guardians-3_nccbq4.png',
  'Colorado Rockies':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379579/Product/app/features/game-center/pick-ems/mlb/colorado-rockies-3_q81zgy.png',
  'Detroit Tigers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379580/Product/app/features/game-center/pick-ems/mlb/detroit-tigers-3_y1hsoo.png',
  'Houston Astros':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379581/Product/app/features/game-center/pick-ems/mlb/houston-astros-3_aucpn4.png',
  'Kansas City Royals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379581/Product/app/features/game-center/pick-ems/mlb/kansas-city-royals-3_m5jq0f.png',
  'Los Angeles Angels':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379580/Product/app/features/game-center/pick-ems/mlb/los-angeles-angels-3_jrl8sr.png',
  'Los Angeles Dodgers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379581/Product/app/features/game-center/pick-ems/mlb/los-angeles-dodgers-3_lz3rrw.png',
  'Miami Marlins':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379581/Product/app/features/game-center/pick-ems/mlb/miami-marlins-3_mcyf8x.png',
  'Milwaukee Brewers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379581/Product/app/features/game-center/pick-ems/mlb/milwaukee-brewers-3_zvqv4i.png',
  'Minnesota Twins':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379581/Product/app/features/game-center/pick-ems/mlb/minnesota-twins-3_ntk3ch.png',
  'New York Mets':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379588/Product/app/features/game-center/pick-ems/mlb/new-york-mets-3_quc6si.png',
  'New York Yankees':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379582/Product/app/features/game-center/pick-ems/mlb/new-york-yankees-3_cei8bx.png',
  'Philadelphia Phillies':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/philadelphia-phillies-3_jweivj.png',
  'Pittsburgh Pirates':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/pittsburgh-pirates-3_urzgbs.png',
  'San Diego Padres':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/san-diego-padres-3_qamkua.png',
  'San Francisco Giants':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379582/Product/app/features/game-center/pick-ems/mlb/san-francisco-giants-3_nvbfnk.png',
  'Seattle Mariners':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/seattle-mariners-3_ltpkr5.png',
  'St. Louis Cardinals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/st-louis-cardinals-3_vu8uuc.png',
  'Tampa Bay Rays':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/tampa-bay-rays-3_iaoajk.png',
  'Texas Rangers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379583/Product/app/features/game-center/pick-ems/mlb/texas-rangers-4_l39kgz.png',
  'Toronto Blue Jays':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379584/Product/app/features/game-center/pick-ems/mlb/toronto-blue-jays-3_hjksvq.png',
  'Washington Nationals':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1739379586/Product/app/features/game-center/pick-ems/mlb/washington-nationals-3_idkukg.png',

  // NCAA Basketball CBC Teams
  'Arizona State Sun Devils':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326514/Product/app/features/game-center/pick-ems/college-basketball-crown/arizona-state-sun-devils.png',
  'Boise State Broncos':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326516/Product/app/features/game-center/pick-ems/college-basketball-crown/boise-state-broncos.png',
  'Butler Bulldogs':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326513/Product/app/features/game-center/pick-ems/college-basketball-crown/butler-bulldogs.png',
  'Cincinnati Bearcats':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326515/Product/app/features/game-center/pick-ems/college-basketball-crown/cincinnati-bearcats.png',
  'Colorado Buffalos':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326514/Product/app/features/game-center/pick-ems/college-basketball-crown/colorado-buffalos.png',
  'DePaul Blue Demons':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326514/Product/app/features/game-center/pick-ems/college-basketball-crown/depaul-blue-demons.png',
  'George Washington Revolutionaries':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326515/Product/app/features/game-center/pick-ems/college-basketball-crown/george-washington-revolutionaries.png',
  'Georgetown Hoyas':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326515/Product/app/features/game-center/pick-ems/college-basketball-crown/georgetown-hoyas.png',
  'Nebraska Cornhuskers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326516/Product/app/features/game-center/pick-ems/college-basketball-crown/nebraska-cornhuskers.png',
  'Oregon State Beavers':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326516/Product/app/features/game-center/pick-ems/college-basketball-crown/oregon-state-bevers.png',
  'Tulane Green Wave':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326517/Product/app/features/game-center/pick-ems/college-basketball-crown/tulane-green-wave.png',
  'UCF Knights':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326517/Product/app/features/game-center/pick-ems/college-basketball-crown/ucf-knights.png',
  'USC Trojans':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326518/Product/app/features/game-center/pick-ems/college-basketball-crown/usc-trojans.png',
  'Utah Utes':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326518/Product/app/features/game-center/pick-ems/college-basketball-crown/utah-utes.png',
  'Villanova Wildcats':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326519/Product/app/features/game-center/pick-ems/college-basketball-crown/villanova-wildcats.png',
  'Washington State Cougars':
    'https://media.vsstatic.com/image/upload/w_200,ar_16:9,c_fill,g_face,dpr_2,r_8,q_auto/v1742326519/Product/app/features/game-center/pick-ems/college-basketball-crown/washington-state-cougars.png',
} as const;
