import { useContext } from 'react';
import { WindowContext } from '../App';
import { FLAGS } from '../constants/Flags';

/**
 * Use this hook to check if a feature flag is enabled
 * Use a flag from the FLAGS enum in `constants/Flags.ts` to check if it is enabled
 * @param flagName - The enum value of the feature flag to check
 * @returns An object with the enabled status of the feature flag
 */
export function useFeatureFlags(flagName: (typeof FLAGS)[keyof typeof FLAGS]) {
  const windowValues = useContext(WindowContext);
  const featureFlags = windowValues.featureFlags;

  if (!featureFlags) {
    return { enabled: false };
  }

  const enabled = featureFlags.find((flag) => flag.flag === flagName)?.enabled || false;

  return { enabled };
}
