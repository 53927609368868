import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Animated, Image, Modal, ScrollView, StyleSheet, View } from 'react-native';
import { WindowContext, queryClient } from '../App';
import { Entry, Play, PlayRequest, PickColors, Game, GameTypes, Challenge } from '../interfaces';
import { postPlay } from '../api';
import { Check, Close, Pending } from './images';
import DText from './DText';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { colorPalette } from '../themes/variables';
import { GradientCountdownCircle } from './GradientCountdownCircle';
import BiggerTouchableOpacity from './BiggerTouchableOpacity';
import { PendingPill } from './NewPickEmGame';
import { SlideInView } from './SlideInView';
import { TeamImages } from '../constants/TeamImages';
import { getChallengeIcon, getGamesType } from '../Utils';
import Margin from './Margin';
import { FavoritePerformerSection } from './FavoritePerformerSection';
import { PlayNextGameOrBackToGameCenterButton } from './PlayNextGameOrBackToGameCenterButton';

const padNumber = (num: number) => {
  return num < 10 ? '0' + num : num;
};

export function TriviaGameModal({
  game,
  onCloseModal,
  challenge,
}: {
  challenge: Challenge;
  game: Game;
  onCloseModal: () => void;
}) {
  // How long each tick is in seconds
  const TICK_RATE = 1;
  // Amount of time before the game starts
  const getReadyTime = 3;
  const [goSeconds, setGoSeconds] = useState(getReadyTime);

  // Amount of time per question
  const questionTimeMax = 10;
  const [questionTime, setQuestionTime] = useState(questionTimeMax);

  // This gets set to true when the go seconds time is 0 (the game starts, which triggers question timers)
  const [startGame, setStartGame] = useState(false);

  // This is the play objecct that will eventually get filled and sent to the server
  const [_play, setPlay] = useState<PlayRequest>({
    gameId: game.id,
    entries: [],
  });

  // This determines whether or not to show the countdown card at the beginning
  const [showCountdownCard, setShowCountdownCard] = useState(true);

  // This state manages whether or not the play record is being submitted to the server
  const [submitting, setSubmitting] = useState(false);
  // If there was an error submitting the play
  const [submitError, setSubmitError] = useState<string | null>(null);

  // The current question index to render, always start at index 0.
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // The list of questions from the game definition
  const questions = useMemo(() => game.definition.questions, [game]);

  // The current question based on the index
  const currentQuestion = useMemo(
    () => questions[currentQuestionIndex],
    [currentQuestionIndex, questions],
  );

  // The answer options for that current question
  const answerOptions = useMemo(() => currentQuestion?.answerOptions, [currentQuestion]) || [];

  // How many total questions there are in this game
  const maxQuestions = questions.length;
  // The maximum index we can hit before the game is done
  const maxQuestionIndex = maxQuestions - 1;

  // This gets filled with the play entries as people answer (or timers run out)
  const [playEntries, setPlayEntries] = useState<Entry[]>([]);

  // This holds the scores for each question (true (question correct), false (question incorrect) or undefined (they didnt answer in time))
  const [scores, setScores] = useState<(boolean | undefined)[]>([]);

  const initialColors = answerOptions.reduce((acc: PickColors, _, index) => {
    acc[index.toString()] = {
      highlight: 'transparent',
      border: 'transparent',
      icon: <></>,
    };
    return acc;
  }, {});

  // This holds the colors for each answer option (highlight, border, icon)
  const [colors, setColors] = useState<PickColors>(initialColors);

  // This determines whether or not to lock the state of the game, so that people can't answer after the question is done
  const [gameLock, setGameLock] = useState(false);
  // This determines how long (in seconds) to lock the game state to show the result
  const GAME_LOCK_TIME = 1.25;

  const [gameResults, setGameResults] = useState<Play | null>(null);

  const closeAndClear = useCallback(() => {
    setGameResults(null);
    onCloseModal();
  }, [onCloseModal]);

  const windowValues = useContext(WindowContext);

  const optimisticGradePlay = useCallback(
    ({ play, game }: { play: PlayRequest; game: Game }) => {
      // Step 1: Create the object without the total points
      const optimisticPlay = {
        ...play,
        entries: play.entries.map((entry) => {
          const question = game.definition.questions.find((q) => q.questionId === entry.questionId);
          const correctAnswerForCurrentQuestion = question?.answerId;
          const isRight = entry.answerId === correctAnswerForCurrentQuestion;
          return {
            ...entry,
            correct: isRight,
            points: isRight ? question?.points || 0 : 0,
          };
        }),
        // Stubbing out the rest of the play object
        id: 0,
        userId: windowValues.accountId || '',
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        gameId: game.id,
        deletedAt: null,
        completedAt: new Date().toISOString(),
        points: 0, // Will be updated in step 2
      };

      // Step 2: Sum up the points and add/update the key
      optimisticPlay.points =
        optimisticPlay.entries.reduce((sum, entry) => sum + entry.points, 0) +
        (game.definition.defaultPointsForPlaying || 0);

      return optimisticPlay as Play;
    },
    [windowValues.accountId],
  );

  const submitPlay = useCallback(
    async (play: PlayRequest) => {
      setSubmitting(true);
      try {
        // Because the server takes time to respond, we want to show the results of the game while the server is processing the play record.
        const optimisticPlay = optimisticGradePlay({ play, game });
        setTimeout(() => {
          setGameResults(optimisticPlay);
        }, GAME_LOCK_TIME * 1000);

        // Here the server is processing the play record
        const postPlayResponse = await postPlay(windowValues)({ play });
        queryClient.invalidateQueries({
          queryKey: ['GameCenterData-Leagues'],
          refetchType: 'all',
        });
        if (postPlayResponse) {
          setSubmitting(false);
          // Now that the submission is done, refresh the data to update the challenge cards.
          await queryClient.refetchQueries(['ChallengeData']);
          await queryClient.refetchQueries(['GameCenterData-Challenges']);
        } else {
          // If there is no resposne, something probably went wrong, show error modal.
          setSubmitError(
            'Something went wrong when submitting your play record. Please refresh and try again',
          );
          setSubmitting(false);
          // TODO: Add error modal.
        }
      } catch (error) {
        // Something went horribly wrong, show error modal.
        setSubmitError(
          'Something went wrong when submitting your play record. Please refresh and try again',
        );
        setSubmitting(false);
      }
    },
    [game, optimisticGradePlay, windowValues],
  );

  // This is the timer that counts down before the game starts (The Get Ready screen)
  useEffect(() => {
    const interval = setInterval(() => {
      if (startGame === false && gameResults === null) {
        setGoSeconds((previousSeconds) => previousSeconds - 1);
      } else {
        return;
      }
    }, TICK_RATE * 1000);

    return () => clearInterval(interval);
  }, [gameResults, startGame]);

  // After they answer (to grade) and whenever the current question changes, update the colors (to reset colors on a new question)
  const gradeQuestion = useCallback(() => {
    const answerOptions = currentQuestion?.answerOptions || [];
    const played = playEntries.find((play) => play.questionId === currentQuestion?.questionId);
    const gradedColors = {} as PickColors;

    if (!played) {
      answerOptions.map((_answerOption, index) => {
        gradedColors[index] = {
          highlight: 'transparent',
          border: 'transparent',
          icon: <></>,
        };
      });
    }
    // If its played, evaluate all the options
    if (played) {
      answerOptions.map((_answerOption, index) => {
        // If we're on the users option check if its correct or not
        const correctAnswerForCurrentQuestion = currentQuestion?.answerId;
        if (played.answerId === index.toString()) {
          const playersChoice = played.answerId;
          const isRight = playersChoice === correctAnswerForCurrentQuestion;
          if (isRight) {
            gradedColors[index] = {
              highlight: Colors.gameCenterQuestionRightHighlight,
              border: Colors.gameCenterQuestionRight,
              icon: <Check width={18} height={18} tintColor={Colors.gameCenterQuestionRight} />,
            };
          } else {
            gradedColors[index] = {
              highlight: Colors.gameCenterQuestionWrongHighlight,
              border: Colors.gameCenterQuestionWrong,
              icon: <Close width={18} height={18} tintColor={Colors.gameCenterQuestionWrong} />,
            };
          }
        } else if (index.toString() === correctAnswerForCurrentQuestion) {
          // If its not the users option, but its the correct answer, highlight it
          gradedColors[index] = {
            highlight: Colors.gameCenterQuestionRightHighlight,
            border: Colors.gameCenterQuestionRight,
            icon: <Check width={18} height={18} tintColor={Colors.gameCenterQuestionRight} />,
          };
        } else {
          if (played?.answerId !== 'none') {
            // Don't grade incorrect answers
            gradedColors[index] = {
              highlight: 'transparent',
              border: 'transparent',
              icon: <></>,
            };
          } else {
            gradedColors[index] = {
              highlight: Colors.gameCenterQuestionWrongHighlight,
              border: Colors.gameCenterQuestionWrong,
              icon: <Close width={18} height={18} tintColor={Colors.gameCenterQuestionWrong} />,
            };
          }
        }
      });
    }
    setColors(gradedColors);
  }, [playEntries, currentQuestion]);

  // As the seconds tick down, if the seconds are less than 0, hide the countdown card and start the game
  useEffect(() => {
    if (goSeconds < 0) {
      setShowCountdownCard(false);
      setStartGame(true);
    }
  }, [goSeconds]);

  // This is the timer that counts down for each question.
  // If the game is locked, its showing the results of the last question so don't reduce the time to a negative number, wait for the next question to start
  useEffect(() => {
    const interval = setInterval(() => {
      if (startGame && questionTime > 0 && !gameLock && gameResults === null) {
        setQuestionTime((previousTime) => previousTime - 1);
      }
    }, TICK_RATE * 1000);

    return () => clearInterval(interval);
  }, [startGame, gameLock, questionTime, gameResults]);

  const addEntry = useCallback(
    (entry: Entry) => {
      setGameLock(true);
      // Update the play entries list for other grading
      setPlayEntries((prevEntries) => {
        const updatedEntries = [...prevEntries, entry];

        // Whenever a play entry is added, append it to the play state that will get sent out in the postPlay request
        setPlay((prevPlay) => {
          const play = {
            ...prevPlay,
            entries: updatedEntries,
          };
          // If we're on the last play, submit the record here with the entries.
          if (currentQuestionIndex === maxQuestionIndex) {
            submitPlay(play);
          }
          return play;
        });
        return updatedEntries;
      });
      const correctAnswer = currentQuestion?.answerId;
      const isCorrect = entry.answerId === correctAnswer;
      setScores((prevScore) => [...prevScore, isCorrect]);
      setQuestionTime(0);
      gradeQuestion();

      setTimeout(() => {
        if (currentQuestionIndex === maxQuestionIndex) {
          return;
        }
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setQuestionTime(questionTimeMax);
        setGameLock(false);
      }, GAME_LOCK_TIME * 1000);
    },
    [currentQuestion?.answerId, currentQuestionIndex, gradeQuestion, maxQuestionIndex, submitPlay],
  );

  // When the question time hits 0, if we're on the last question, submit the play.
  // Otherwise they didnt answer before the timer ran out, so find the current play entry, check that it is undefined and then set it to "none"
  useEffect(() => {
    if (questionTime === 0) {
      const currentPlayEntry = playEntries.find(
        (entry) => entry.questionId === currentQuestion?.questionId,
      );
      if (currentPlayEntry?.answerId === undefined) {
        addEntry({
          questionId: currentQuestion?.questionId || '',
          answerId: 'none',
        });
        return;
      }
    }
    return;
  }, [questionTime, currentQuestion, addEntry, playEntries]);

  useEffect(() => {
    gradeQuestion();
  }, [playEntries, currentQuestionIndex, gradeQuestion]);

  if (gameResults) {
    return (
      <TriviaGameResultModal
        game={game}
        gameResults={gameResults}
        onCloseModal={closeAndClear}
        challenge={challenge}
      />
    );
  }

  return (
    <View
      style={{
        alignItems: 'center',
        width: '100%',
        maxWidth: 475,
        backgroundColor: 'transparent',
        alignSelf: 'center',
      }}
    >
      <View style={{ width: '100%' }}>
        <View style={[styles.container, { backgroundColor: '#1d2141', borderRadius: 4 }]}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              padding: 16,
              gap: 8,
              overflow: 'hidden',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                <View
                  style={
                    {
                      borderBottomColor: colorPalette.neutral['200'],
                      borderBottomWidth: 0.5,
                      width: 'fit-content',
                    } as any
                  }
                >
                  <DText color={colorPalette.neutral['200']} fontSize={Fonts.font12}>
                    {`Question ${currentQuestionIndex + 1}/${maxQuestions}`}
                  </DText>
                </View>
                <Score scores={scores} questions={maxQuestions} />
              </View>
              <GradientCountdownCircle
                text={':' + padNumber(questionTime)}
                backgroundColor={'transparent'}
                width={54}
                steps={questionTimeMax}
                index={questionTime > 0 ? questionTime : 0}
              />
            </View>
            {showCountdownCard && <CountdownCard time={goSeconds} steps={getReadyTime} />}
            <SlideInView
              offset={-650}
              type={'tap'}
              direction="forward"
              question={currentQuestion.questionId}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 32,
                width: '100%',
              }}
            >
              <DText
                center={true}
                color={'white'}
                fontWeight={900}
                fontSize={Fonts.font18}
                style={{ minHeight: 62 }}
              >
                {currentQuestion?.questionText}
              </DText>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: 16,
                }}
              >
                {answerOptions?.map((answerOption, index) => {
                  return (
                    <BiggerTouchableOpacity
                      key={index}
                      disabled={gameLock || submitting}
                      onPress={() =>
                        addEntry({
                          questionId: currentQuestion?.questionId || '',
                          answerId: index.toString(),
                        })
                      }
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 60,
                        borderRadius: 4,
                        paddingHorizontal: 16,
                        backgroundColor: Colors.cardColor,
                        position: 'relative',
                      }}
                    >
                      <Animated.View
                        style={{
                          position: 'absolute',
                          top: 0,
                          flexDirection: 'row',
                          width: '100%',
                          height: 60,
                          borderRadius: 4,
                          paddingRight: 16,
                          paddingLeft: 16,
                          backgroundColor: colors[index]?.highlight,
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          zIndex: 10,
                        }}
                      >
                        <View
                          style={{
                            height: 22,
                            width: 22,
                            borderWidth: 1,
                            borderRadius: 11,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderColor: colors[index]?.border,
                          }}
                        >
                          {colors[index]?.icon}
                        </View>
                      </Animated.View>
                      <DText
                        center={true}
                        style={{ zIndex: 10000, marginLeft: 16, marginRight: 16 }}
                        color={'white'}
                        fontSize={Fonts.font16}
                        fontWeight={900}
                      >
                        {answerOption}
                      </DText>
                    </BiggerTouchableOpacity>
                  );
                })}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginVertical: 4,
                }}
              >
                <DText
                  style={{
                    textAlign: 'center',
                  }}
                  color={Colors.red}
                  fontSize={Fonts.font14}
                >
                  {submitError}
                </DText>
              </View>
            </SlideInView>
          </View>
        </View>
      </View>
    </View>
  );
}

function Score({ scores, questions }: { scores: (boolean | undefined)[]; questions: number }) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
      }}
    >
      {Array.from({ length: questions }).map((_num, index) => {
        return (
          <View
            key={index}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              width: 20,
              height: 20,
              borderColor:
                scores[index] === undefined
                  ? colorPalette.neutral['400']
                  : scores[index] === true
                  ? Colors.questionRightHighlight
                  : Colors.questionWrongHighlight,
              borderWidth: 1,
              backgroundColor: 'transparent',
            }}
          >
            {scores[index] === true ? (
              <Check width={16} height={16} tintColor={Colors.questionRightHighlight} />
            ) : scores[index] === false ? (
              <Close width={16} height={16} tintColor={Colors.questionWrongHighlight} />
            ) : (
              <></>
            )}
          </View>
        );
      })}
    </View>
  );
}

function CountdownCard({ time, steps }: { time: number; steps: number }) {
  const BLUR_SIZE = 5;

  const timerStyles: any = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    borderRadius: 8,
    WebkitBackdropFilter: 'blur(' + BLUR_SIZE + 'px)',
    backdropFilter: 'blur(' + BLUR_SIZE + 'px)',
  };

  return (
    <View style={timerStyles}>
      <DText fontSize={Fonts.font14} fontWeight={Fonts.Bold} color={'white'}>
        Get Ready!
      </DText>
      <GradientCountdownCircle
        text={':' + padNumber(time)}
        backgroundColor={'#4a4e67'}
        width={78}
        fontSize={Fonts.font24}
        steps={steps}
        index={time > 0 ? time : 0}
      />
    </View>
  );
}

export function GameResultContent({
  game,
  showDetails = false,
  name,
  userId,
  gameResults: existingGameResults,
  totalPointsReceived: existingTotalPointsReceived,
  plays,
  toggleShowDetails = () => {},
}: {
  game: Game;
  gameResults?: Play;
  showDetails?: boolean;
  name?: string;
  userId?: string;
  totalPointsReceived?: number;
  plays?: Play[];
  toggleShowDetails?: () => void;
}) {
  const matchGame = (plays?: Play[]) =>
    plays?.find((play) => play.userId === userId && game.id === play.gameId);
  const userPlayedGame = matchGame(game.plays) || matchGame(plays);
  const gameResults = existingGameResults || userPlayedGame || game?.plays[0];
  const totalPointsReceived = existingTotalPointsReceived || gameResults?.points;

  if (!userPlayedGame && !gameResults) {
    return null;
  }

  const nameOrYou = name || 'You';
  const namesOrYour = name ? name + "'s" : 'Your';

  const totalQuestions = gameResults.entries.length;
  const totalCorrect = gameResults.entries.filter((entry) => entry.correct).length;
  const gameDate = new Date(gameResults.createdAt).toLocaleDateString();

  return (
    <View
      style={{
        borderRadius: 4,
        width: '100%',
        backgroundColor: 'rgba(255,255,255,.1)',
        marginBottom: 8,
        padding: 14,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <BiggerTouchableOpacity onPress={toggleShowDetails}>
        <View
          style={{
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: 2,
          }}
        >
          <DText color={'white'} fontSize={Fonts.font14} fontWeight={800}>
            {game.name} • {gameDate}
          </DText>
          {showDetails && (
            <DText
              color={colorPalette.neutral[300]}
              fontSize={Fonts.font13}
              style={
                {
                  borderBottom: '1px solid ' + colorPalette.neutral[300],
                  height: 'fit-content',
                  whiteSpace: 'nowrap',
                } as any
              }
            >
              See Details
            </DText>
          )}
        </View>
      </BiggerTouchableOpacity>
      <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ display: 'flex' }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
          }}
        >
          {gameResults.entries
            .sort((entry1, entry2) => {
              return parseInt(entry1.questionId) - parseInt(entry2.questionId);
            })
            .map((result, index) => {
              const number = index + 1;
              return <TriviaEntryResult key={index} result={result} number={number} game={game} />;
            })}
        </View>
      </ScrollView>
      <BiggerTouchableOpacity onPress={toggleShowDetails}>
        <View
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <DText fontSize={Fonts.font14} color={'white'}>
            {gameResults.entries.some((entry) => entry.correct === undefined)
              ? `Check back after the game is over to see ${namesOrYour.toLowerCase()} results`
              : `${nameOrYou} got ${totalCorrect} of ${totalQuestions} questions correct.`}
          </DText>
        </View>
        <View
          style={{
            width: '100%',
            borderColor: 'white',
            borderWidth: 0.5,
            height: 1,
            marginTop: 8,
            marginBottom: 0,
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 8,
          }}
        >
          <DText fontSize={Fonts.font14} color={'white'}>
            Total Entries Earned
          </DText>
          <DText fontSize={Fonts.font14} fontWeight={900} color={Colors.white}>
            {gameResults.entries.some((entry) => entry.correct === undefined) ? (
              <PendingPill />
            ) : (
              '+' + totalPointsReceived
            )}
          </DText>
        </View>
      </BiggerTouchableOpacity>
    </View>
  );
}

function TriviaGameResultModal({
  game,
  gameResults,
  onCloseModal,
  name,
  challenge,
}: {
  game: Game;
  gameResults: Play;
  onCloseModal: () => void;
  name?: string;
  challenge?: Challenge;
}) {
  const totalPointsReceived = gameResults.points;

  const nameOrYou = name || 'You';

  const { accountId, setChallenge } = useContext(WindowContext);

  const backToGameCenter = useCallback(() => {
    return setChallenge(undefined);
  }, [setChallenge]);

  const activeGameToBePlayed = challenge?.games?.find(
    ({ id, active, plays }) =>
      game.id !== id && active && !plays.some((play) => play.userId === accountId),
  );
  return (
    <Modal
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,.2)',
        alignSelf: 'center',
      }}
      animationType={'fade'}
      transparent={true}
      onRequestClose={onCloseModal}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          height: '100%',
          maxWidth: 475,
          paddingTop: 90,
          backgroundColor: 'rgba(0, 21, 38, 0.8)',
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 475,
            backgroundColor: 'transparent',
          }}
        >
          <View style={{ width: '100%', paddingHorizontal: 16 }}>
            <View
              style={{
                backgroundColor: '#1d2141',
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                padding: 20,
                paddingTop: 36,
              }}
            >
              <View
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  marginBottom: 8,
                }}
              >
                <DText center={true} color={'white'} fontSize={Fonts.font14} fontWeight={800}>
                  {`${nameOrYou} earned ${totalPointsReceived} entries by playing today’s game!`}
                </DText>
              </View>
              <GameResultContent
                game={game}
                gameResults={gameResults}
                totalPointsReceived={totalPointsReceived}
                userId={accountId}
              />
              <View style={{ width: '100%' }}>
                <FavoritePerformerSection performerId={challenge?.definition.performerId} />
                <PlayNextGameOrBackToGameCenterButton
                  onPress={() => {
                    onCloseModal();
                    if (!activeGameToBePlayed) {
                      backToGameCenter();
                    }
                  }}
                  activeGameToBePlayed={Boolean(activeGameToBePlayed)}
                />
                {challenge?.definition.featuredLeagueId && (
                  <View style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                    <DText
                      color={colorPalette.neutral[200]}
                      fontSize={18}
                      fontWeight={800}
                      style={{
                        marginTop: 12,
                      }}
                    >
                      Leagues
                    </DText>
                    <BiggerTouchableOpacity
                      onPress={() => {
                        window.location.replace(
                          window.location.href.split('?')[0] +
                            `?leagueId=${challenge?.definition.featuredLeagueId}`,
                        );
                      }}
                      style={{
                        width: '100%',
                        minHeight: 77,
                        paddingLeft: 16,
                        paddingRight: 24,
                        paddingTop: 16,
                        paddingBottom: 16,
                        marginBottom: 16,
                        borderRadius: 8,
                        backgroundColor: 'rgba(255,255,255,.1)',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <View
                          style={{
                            width: 46,
                            height: 46,
                            borderRadius: 23,
                            backgroundColor: 'rgba(255,255,255,.1)',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <DText fontSize={24} color="white" fontWeight={900}>
                            {getChallengeIcon(challenge?.type)}
                          </DText>
                        </View>
                        <Margin width={12} />
                        <View
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <DText
                            color="white"
                            fontSize={Fonts.font15}
                            lineHeight={'150%'}
                            fontWeight={900}
                          >
                            {challenge?.name}
                          </DText>
                          <DText color="white" fontSize={Fonts.font13} lineHeight={'150%'}>
                            {'See your spot in our public league!'}
                          </DText>
                        </View>
                        <Margin width={12} />
                      </View>
                    </BiggerTouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
          <BiggerTouchableOpacity
            onPress={onCloseModal}
            style={{
              position: 'absolute',
              top: 12,
              right: 32,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Close height={18} width={18} tintColor={'white'}></Close>
          </BiggerTouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

export function TriviaEntryResult({
  number,
  result,
  game,
}: {
  number: number;
  result: Play['entries'][number];
  game?: Game;
}) {
  const color =
    result.correct === undefined
      ? Colors.borderBlue
      : result.correct
      ? Colors.gameCenterQuestionRight
      : Colors.gameCenterQuestionWrong;
  const size = 16;
  const resultIcon = (
    <View
      style={
        {
          width: 18,
          height: 18,
          borderWidth: 2,
          borderRadius: 18,
          borderColor: color,
          backgroundColor: Colors.betchaBlack,
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 2px 4px 0px ' + color,
        } as any
      }
    >
      {result.correct === undefined ? (
        <Pending width={10} height={8} tintColor={color} />
      ) : result.correct ? (
        <Check width={size} height={size} tintColor={color} />
      ) : (
        <Close width={size} height={size} tintColor={color} />
      )}
    </View>
  );

  const { challenge } = useContext(WindowContext);
  const gameTypesToShowImages: GameTypes[] = ['curated_poll', 'pickem'];
  const showImages = challenge && gameTypesToShowImages.includes(getGamesType({ challenge }));

  const question = game?.definition.questions.find((q) => q.questionId === result.questionId);
  const answerOption = question?.answerOptions[parseInt(result.answerId as string)];
  const answerOptionsImages = question?.answerOptionsImages;
  const answerOptionImage =
    showImages &&
    (TeamImages[answerOption as keyof typeof TeamImages] ??
      answerOptionsImages?.find((image) => image.answerId === result.answerId)?.url);

  return (
    <View
      style={{
        marginTop: 6,
        marginBottom: 2,
        justifyContent: 'flex-end',
        marginLeft: 8,
      }}
    >
      <View
        style={{
          backgroundColor: Colors.betchaBlack,
          width: 36,
          height: 36,
          borderRadius: 18,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 36,
            height: 36,
            backgroundColor: 'transparent',
            borderRadius: 18,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <View style={{ position: 'absolute', left: -8, top: -6, zIndex: 1 }}>{resultIcon}</View>
          {answerOptionImage && result.answerId ? (
            <View
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Image
                source={{ uri: answerOptionImage }}
                style={{
                  width: '100%',
                  height: '100%',
                  resizeMode: 'stretch',
                  borderRadius: 18,
                }}
              />
            </View>
          ) : (
            <>
              <DText
                style={{ position: 'absolute', top: 8, left: 8 }}
                fontSize={Fonts.font12}
                fontWeight={900}
                color="white"
              >
                #
              </DText>
              <DText fontSize={Fonts.font20} fontWeight={900} color="white">
                {number}
              </DText>
            </>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    flex: 1,
    backgroundColor: colorPalette.neutral[800],
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 475,
  },
});
