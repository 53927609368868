import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { FLAGS } from '../constants/Flags';
import { View } from 'react-native';
import { Challenge, PerformerSummary, RNMessage } from '../interfaces';
import FavoritePerformerButton from './FavoritePerformerButton';
import DText from './DText';
import { colorPalette } from '../themes/variables';
import Fonts from '../constants/Fonts';
import { useEffect, useState } from 'react';

export function FavoritePerformerSection({
  performerId,
}: {
  performerId: Challenge['definition']['performerId'];
}) {
  const [performer, setPerformer] = useState<PerformerSummary | undefined>();
  const { enabled: gameCenterFavoritingEnabled } = useFeatureFlags(FLAGS.GAME_CENTER_FAVORITING);

  useEffect(() => {
    if (!performerId) {
      return;
    }
    // On mount, get the performer summary, without the useEffect, the postMessage gets sent on every rerender
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'GET_PERFORMER_SUMMARY_BY_ID', body: { performerId } }),
    );
  }, [performerId]);

  // Register an event listener and listen for the messages from the RN app, specifically the performer summary message and update the performer state
  useEffect(() => {
    const handleMessage = (nativeEvent: MessageEvent<string>) => {
      const data = JSON.parse(nativeEvent.data) as RNMessage<PerformerSummary>;
      if (data.type === 'PERFORMER_SUMMARY') {
        setPerformer(data.body);
      }
    };
    window.addEventListener('message', handleMessage, true);

    return () => {
      window.removeEventListener('message', handleMessage, true);
    };
  }, []);

  if (!gameCenterFavoritingEnabled || !performerId || !performer) {
    return null;
  }

  const notificationText = performer.isFavorited
    ? `You'll be notified when ${performer?.performerName} is in town.`
    : `Get notified when ${performer?.performerName} is in town.`;

  return (
    <View style={{ display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 12 }}>
      <DText
        color={colorPalette['white']['100']}
        fontSize={Fonts.font16}
        fontWeight={800}
        center
        style={{ marginBottom: 12 }}
      >
        {notificationText}
      </DText>
      <FavoritePerformerButton performer={performer} />
    </View>
  );
}
